import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Button from 'components/Button'
import Markdown from 'components/Markdown'
import { Helmet } from 'react-helmet'
import { itemList, jobPosting } from '../../../lib/structured-data'

const JobOpenings = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2 className="headline">{props.title}</h2>
        <div className="jobs-wrapper column--measure-medium">
          {props.jobOpenings && props.jobOpenings.length > 0 ? (
            <>
              <Helmet>
                <script type="application/ld+json">
                  {JSON.stringify(itemList(props.jobOpenings.map(jobPosting)))}
                </script>
              </Helmet>
              {props.jobOpenings.map((opening, i) => {
                return (
                  <div key={`opening-${i}`} className="job__item">
                    <div className="job--details">
                      <p className="job--title">{opening.title}</p>
                      {opening.workload} – {opening.jobLocation}
                    </div>

                    <Button
                      className="btn--primary btn--small"
                      link={{ page: { slug: opening.detailLink } }}
                    >
                      Details
                    </Button>
                  </div>
                )
              })}
            </>
          ) : (
            <div>
              <Markdown>{props.noOpeningsText}</Markdown>
              <Button className="btn--primary" link={props.noOpeningsLink}>
                {props.noOpeningsLink.label}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

JobOpenings.propTypes = {
  title: PropTypes.string.isRequired,
  jobOpenings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
      workload: PropTypes.string,
      jobLocation: PropTypes.string,
      link: linkPropType,
    })
  ),
  noOpeningsText: PropTypes.string,
  noOpeningsLink: linkPropType,
}

export default JobOpenings
